import { settings } from 'settings';

const AUTH_URI = 'auth/';
const LOGIN = `${settings.DASHBOARD_API_URL}${AUTH_URI}get_token/`;
const REFRESH = `${settings.DASHBOARD_API_URL}${AUTH_URI}refresh_token/`;
const VERIFY = `${settings.DASHBOARD_API_URL}${AUTH_URI}verify_token/`;

export const AUTH_URLS = { LOGIN, REFRESH, VERIFY };

export const TRAINEE = 'TRAINEE';
export const TRAINER = 'TRAINER';
export const ADMIN = 'ADMIN';
