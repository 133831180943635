class MessageQueue {
  static STATES = {
    LOCKED: 'LOCKED',
    UNLOCKED: 'UNLOCKED',
  };

  constructor() {
    this.messages = [];
    this.state = MessageQueue.STATES.LOCKED;
    // Callback
    this.handleMessage = null;
  }

  _enqueue = (item) => {
    this.messages.push(item);
  };

  _consume = () => {
    this.messages.forEach((message) => {
      this.handleMessage(message);
    });
    this.messages = [];
  };

  unlock = () => {
    this._consume();
    this.state = MessageQueue.STATES.UNLOCKED;
  };

  isLocked = () => this.state === MessageQueue.STATES.LOCKED;

  addMessage = (message) => {
    if (this.isLocked()) this._enqueue(message);
    else this.handleMessage(message);
  };

  onMessage = (func) => {
    this.handleMessage = func;
  };
}

export const msgQueue = new MessageQueue();
