import { settings } from 'settings/index';

import {
  PING_INTERVAL,
  PONG_TIMEOUT,
  RECOVER_TIMEOUT,
  RECOVER_ATTEMPTS,
  PING,
  PONG,
  SOCKET_CLOSED,
  SOCKET_STATES,
} from './constants';

export default class SocketManager {
  constructor() {
    this.socket = null;
    this.pingInterval = null;
    this.pongTimeout = null;
    this.recovering = false;
    this.logging = false;
    this.token = null;
    // Callback
    this.handleMessage = null;
  }

  _ping = () => {
    this.socket.send(PING);
    this.pongTimeout = setTimeout(() => {
      this.socket.close(4008, 'ping_timeout');
    }, PONG_TIMEOUT);
  };

  _onPong = () => {
    clearTimeout(this.pongTimeout);
  };

  _recover = () => {
    if (!this.recovering) {
      this.recovering = true;
      for (let i = 0; i < RECOVER_ATTEMPTS; i++) {
        setTimeout(() => {
          if (this.getState() === SOCKET_CLOSED) {
            this._connect();
          }
        }, RECOVER_TIMEOUT * (i + 1));
      }
    }
  };

  _listen = () => {
    this.socket.onopen = (event) => {
      this.pingInterval = setInterval(this._ping, PING_INTERVAL);
      this.recovering = false;
      this._log(event);
    };
    this.socket.onclose = (event) => {
      clearInterval(this.pingInterval);
      this._recover();
      this._log(event);
    };
    this.socket.onmessage = (event) => {
      const message = event.data;
      if (message === PONG) this._onPong();
      else {
        this.handleMessage(JSON.parse(event.data));
        this._log(event);
      }
    };
  };

  _log = (event) => {
    if (this.logging) {
      console.log(
        `event: ${event}
        state: ${this.getState()}
        socket_obj: ${this.socket}`
      );
    }
  };

  _connect = () => {
    this.socket = new WebSocket(`${settings.DASHBOARD_SERVICE_URL}?token=${this.token}`);
    this._listen();
  };

  getState = () => SOCKET_STATES[this.socket.readyState];

  connect = (token) => {
    this.token = token;
    this._connect();
  };

  onMessage = (func) => {
    this.handleMessage = func;
  };

  send = (message) => this.socket.send(message);
}
