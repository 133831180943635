import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { settings } from 'settings/index';
import { TRAINER, ADMIN, TRAINEE } from '../Account/constants';
import { SESSION_STATUSES } from './constants';

const mapStateToProps = (state) => ({ userType: state.account.userType });

@connect(mapStateToProps, null)
export default class Sessions extends React.Component {
  static propTypes = {
    sessionsList: PropTypes.array,
    onlyMultiplayer: PropTypes.bool,
    sessions: PropTypes.object,
    token: PropTypes.string,
    userType: PropTypes.string,
  };

  userIsTrainee = this.props.userType.toUpperCase() === TRAINEE;

  getRedirectUrl = () =>
    [TRAINER, ADMIN].includes(this.props.userType.toUpperCase())
      ? settings.CB_FRONT_URL
      : settings.TRADING_FRONT_URL;

  sessionsToDOM = () =>
    this.props.sessionsList
      .sort((s1Id, s2Id) => {
        const s1 = this.props.sessions.items[s1Id];
        const s2 = this.props.sessions.items[s2Id];
        return new Date(s2.start_date).getTime() - new Date(s1.start_date).getTime();
      })
      .filter((sessionId) => {
        const session = this.props.sessions.items[sessionId];
        return this.props.onlyMultiplayer ? session.session_type === 1 : true;
      })
      .map((sessionId) => {
        const session = this.props.sessions.items[sessionId];
        const status = SESSION_STATUSES[session.status].toLowerCase();
        const startDate = new Date(session.start_date);
        const endDate = new Date(session.end_date);
        return (
          <tr key={sessionId}>
            <td>{session.scenario_name}</td>
            <td>
              <span className={`label ${status}`}>{status.toUpperCase()}</span>
            </td>
            <td>{`${startDate.toLocaleDateString()} ${startDate.toLocaleTimeString()}`}</td>
            <td>
              {session.end_date
                ? `${endDate.toLocaleDateString()} ${endDate.toLocaleTimeString()}`
                : 'In progress'}
            </td>
            <td>
              {status === 'active' ? (
                <a
                  target="_blank"
                  href={`${this.getRedirectUrl()}${session.uuid}/?token=${this.props.token}`}
                  rel="noreferrer"
                >
                  <button className="btn-blue-big" type="button">
                    <i className="fa fa-play-circle" />
                    <span>ENTER</span>
                  </button>
                </a>
              ) : (
                <a
                  target="_blank"
                  href={`${this.getRedirectUrl()}${session.uuid}/?token=${this.props.token}`}
                  rel="noreferrer"
                >
                  <button className="btn-green" type="button">
                    <i className="fa fa-chart-bar" />
                  </button>
                </a>
              )}
            </td>
          </tr>
        );
      });

  render() {
    const sessions = this.sessionsToDOM();
    return (
      <div className="table-container">
        {sessions.length ? (
          <table>
            <thead>
              <tr>
                <td>SESSION NAME</td>
                <td>STATUS</td>
                {/* <i className='fa fa-caret-down' /> */}
                <td>START DATE</td>
                <td>END DATE</td>
                <td />
              </tr>
            </thead>
            <tbody>{sessions}</tbody>
          </table>
        ) : null}
      </div>
    );
  }
}
