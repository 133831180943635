import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingOutlined } from '@ant-design/icons';

import logoTraderion from 'img/logo_vertical.png';
import { login } from '../actions';
import ErrorHandler from './ErrorHandler';

const getErrors = (error) => {
  const result = {
    usernameErrors: [],
    passwordErrors: [],
    nonFieldErrors: [],
  };

  if (!error || !error.response) return result;

  const { username, password, non_field_errors } = error.response.data;

  if (username) result.usernameErrors = username;
  if (password) result.passwordErrors = password;
  if (non_field_errors) result.nonFieldErrors = non_field_errors;

  return result;
};

const Login = () => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const { error } = useSelector((state) => state.account);
  const { usernameErrors, passwordErrors, nonFieldErrors } = getErrors(error);

  const handleAuth = (name, pwd) => {
    setLoading(true);
    dispatch(login({ username: name, password: pwd }));
  };

  const handleKeyPress = (e) => {
    if (e.key !== 'Enter') return;
    handleAuth(username, password);
  };

  useEffect(() => {
    setLoading(false);
  }, [error]);

  return (
    <div className="login" onKeyPress={handleKeyPress} tabIndex="-1">
      <div className="login__graphics">
        <img src={logoTraderion} alt="Logo Traderion" className="login__logo" />
      </div>
      <div className="login__container">
        <div className="login__form">
          <h1 className="login__title">Login</h1>
          <ErrorHandler errors={usernameErrors}>
            <div className="field">
              <input
                className="field__input"
                type="text"
                required
                onChange={(e) => setUsername(e.target.value)}
              />
              <div className="field__label">Username</div>
            </div>
          </ErrorHandler>
          <ErrorHandler errors={passwordErrors}>
            <div className="field">
              <input
                className="field__input"
                type="password"
                required
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className="field__label">Password</div>
            </div>
          </ErrorHandler>
          <ErrorHandler errors={nonFieldErrors}>
            <button
              type="button"
              className="login__submit"
              onClick={() => handleAuth(username, password)}
            >
              {loading ? [<LoadingOutlined />, ' '] : null}Log in
            </button>
          </ErrorHandler>
        </div>
      </div>
    </div>
  );
};

export default Login;
