import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { expecting } from 'utils/expecting';
import Class from './Class';

const mapStateToProps = (state) => ({
  classes: state.entities.classes,
  sessions: state.entities.sessions,
  scenarios: state.entities.scenarios,
  token: state.account.token,
});

@connect(mapStateToProps, null)
@expecting({ name: 'classes' }, { name: 'sessions' }, { name: 'scenarios' })
export default class Classes extends Component {
  static propTypes = {
    classes: PropTypes.object,
    sessions: PropTypes.object,
    scenarios: PropTypes.object,
    token: PropTypes.string,
  };

  render() {
    const classes = Object.values(this.props.classes.items).map((klass) => (
      <Class
        klass={klass}
        sessions={this.props.sessions}
        scenarios={this.props.scenarios}
        key={klass.id}
        token={this.props.token}
      />
    ));
    return <div className="main-container">{classes}</div>;
  }
}
