export const LOGIN = 'LOGIN';
export const login = ({ username, password }) => ({
  type: LOGIN,
  username,
  password,
});

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const REFRESH_SUCCESS = 'REFRESH_SUCCESS';
export const REFRESH_ERROR = 'REFRESH_ERROR';

export const LOGOUT = 'LOGOUT';
export const logout = () => ({ type: LOGOUT });

export const VERIFY_TOKEN = 'VERIFY_TOKEN';
export const verifyToken = (token) => ({ type: VERIFY_TOKEN, token });

export const VERIFY_TOKEN_SUCCESS = 'VERIFY_TOKEN_SUCCESS';
export const VERIFY_TOKEN_ERROR = 'VERIFY_TOKEN_ERROR';
