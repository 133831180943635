import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import URLSearchParams from 'url-search-params';

import Account from '.';
import { verifyToken } from './actions';
import LoadingComponent from 'reusable/LoadingComponent';

const mapStateToProps = (state) => ({
  isAuthenticated: state.account.isAuthenticated,
  token: state.account.token,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ verifyToken }, dispatch);

function withAuth(WrappedComponent) {
  @connect(mapStateToProps, mapDispatchToProps)
  class withAuth extends Component {
    static propTypes = {
      isAuthenticated: PropTypes.bool,
      token: PropTypes.string,

      verifyToken: PropTypes.func,
    };

    constructor(props) {
      super(props);
      this.nextParam = null;
    }

    componentDidMount() {
      const urlParams = new URLSearchParams(window.location.search);
      this.nextParam = urlParams.get('next');
      if (!this.props.isAuthenticated && this.props.token) {
        this.props.verifyToken(this.props.token);
      }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (nextProps.isAuthenticated && this.nextParam) {
        window.location = `${this.nextParam}?token=${nextProps.token}`;
      }
    }

    render() {
      const urlParams = new URLSearchParams(window.location.search);
      if (!this.props.isAuthenticated) {
        return <Account />;
      }
      if (urlParams.get('next')) {
        return <LoadingComponent />;
      }
      return <WrappedComponent {...this.props} />;
    }
  }
  return withAuth;
}

export default withAuth;
