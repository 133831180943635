export class ApiCallReducers {
  /* actions is an object of {fire: String actionType, success: String actionSuccessType, fail: String actionFailType}.
  options is an object with the following keys:
    successCallback: function to be called on response payload,
    resourceDescriptor: the object key where the response will be set,
    interState: the intermediary state of the request descriptor  */
  constructor(actions, options = {}) {
    this.actions = actions;
    this.options = options;
    this.successCallback =
      this.options.successCallback instanceof Function
        ? this.options.successCallback
        : (data) => data;
    this.stateDescriptor = this._getStateDescriptor();
    this.initialState = this._getInitialState();
    this.reducers = this._createReducers();
  }

  _getStateDescriptor = () => ({
    resourceDescriptor: this.options.resourceDescriptor || 'items',
    interState: this.options.interState || 'fetching',
    error: 'error',
  });

  _getInitialState = () => ({
    [this.stateDescriptor.resourceDescriptor]: null,
    [this.stateDescriptor.interState]: false,
    [this.stateDescriptor.error]: null,
  });

  _createReducers = () => {
    const actions = {
      fire: { name: null, func: null },
      success: { name: null, func: null },
      fail: { name: null, func: null },
    };
    this._createNames(actions);
    this._createFunctions(actions);
    return Object.values(actions).reduce(
      (acc, action) => ({ ...acc, [action.name]: action.func }),
      {}
    );
  };

  _createNames = (actions) => {
    actions.fire.name = this.actions.fire;
    actions.success.name = this.actions.success;
    actions.fail.name = this.actions.fail;
  };

  _createFunctions = (actions) => {
    actions.fire.func = (state) => ({
      ...state,
      [this.stateDescriptor.interState]: true,
    });
    actions.success.func = (state, action) => ({
      ...state,
      [this.stateDescriptor.resourceDescriptor]: this.successCallback(action.payload),
      [this.stateDescriptor.interState]: false,
    });
    actions.fail.func = (state, action) => ({
      ...state,
      [this.stateDescriptor.error]: action.error,
      [this.stateDescriptor.interState]: false,
    });
  };

  addCustomActions = (handlers) => {
    Object.entries(handlers).forEach(([actionType, handler]) => {
      this.reducers[actionType] = handler;
    });
  };

  handler =
    () =>
    (state = this.initialState, action) =>
      this.reducers[action.type] ? this.reducers[action.type](state, action) : state;
}
