import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel } from '@material-ui/core';

import Sessions from '../Sessions';
import Scenarios from '../Scenarios';
import { toggleChildComponent } from './actions';
import { SESSIONS_VIEW, SCENARIOS_VIEW } from './constants';

const mapStateToProps = (state) => ({ view: state.views.classes });
const mapDispatchToProps = (dispatch) => bindActionCreators({ toggleChildComponent }, dispatch);

@connect(mapStateToProps, mapDispatchToProps)
export default class Class extends React.Component {
  static propTypes = {
    klass: PropTypes.object,
    sessions: PropTypes.object,
    scenarios: PropTypes.object,
    token: PropTypes.string,
    view: PropTypes.object,

    toggleChildComponent: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      onlyMultiplayer: false,
    };
  }

  handleToggle = (child) => this.props.toggleChildComponent(this.props.klass.id, child);

  render() {
    const { klass } = this.props;
    const { child } = this.props.view[klass.id];
    const { onlyMultiplayer } = this.state;

    return (
      <div className="class">
        <div className="main-container-header">
          <div className="classes-container">
            <div className="content-holder">
              <span>{klass.name}</span>
              <div>
                Starts on
                {new Date(klass.start_date).toLocaleDateString()}
                {klass.end_date && ` / Ends on ${new Date(klass.start_date).toLocaleDateString()}`}
              </div>
            </div>
            <div className="status-holder">
              {klass.is_ongoing ? (
                <span className="active">
                  <i className="fa fa-play-circle" /> Active class{' '}
                </span>
              ) : (
                <span className="inactive">
                  <i className="fa fa-stop-circle" /> Inactive class{' '}
                </span>
              )}
              <span>
                <i className="fa fa-user-secret" /> 1 trainer{' '}
              </span>
              <span>
                <i className="fa fa-users" /> {klass.user_count} trainees{' '}
              </span>
            </div>
          </div>
          <div className="content-options">
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.onlyMultiplayer}
                  onChange={() => this.setState({ onlyMultiplayer: !onlyMultiplayer })}
                  color="primary"
                />
              }
              label="SHOW ONLY MULTIPLAYER SESSIONS"
            />
            <div>
              {child === SESSIONS_VIEW ? (
                <button
                  type="button"
                  className="btn-blue-big"
                  onClick={() => this.handleToggle(SCENARIOS_VIEW)}
                >
                  <i className="fa fa-plus" /> <span>CREATE A NEW SESSION </span>
                </button>
              ) : (
                <button
                  type="button"
                  className="btn-blue-big"
                  onClick={() => this.handleToggle(SESSIONS_VIEW)}
                >
                  <i className="fas fa-bullhorn" /> <span>LIVE SESSIONS </span>
                </button>
              )}
            </div>
            <button className="btn-blue" type="button">
              <i className="fa fa-info" />
            </button>
          </div>
        </div>
        <div className="table-container">
          {child === SESSIONS_VIEW ? (
            <Sessions
              onlyMultiplayer={this.state.onlyMultiplayer}
              sessionsList={klass.training_sessions}
              sessions={this.props.sessions}
              token={this.props.token}
            />
          ) : (
            <Scenarios scenarios={this.props.scenarios} classId={klass.id} />
          )}
        </div>
      </div>
    );
  }
}
