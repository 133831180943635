import React from 'react';
import { getDisplayName } from './helpers';
import LoadingComponent from 'reusable/LoadingComponent';

export const expecting =
  (...entities) =>
  (WrappedComponent) =>
    class extends WrappedComponent {
      static displayName = `Expecting(${getDisplayName(WrappedComponent)})`;

      constructor(props) {
        super(props);
        this.loaded = this.getEntitiesState(props);
      }

      getEntitiesState = (props) =>
        !entities
          .map((resource) => {
            const descriptor = resource.descriptor || 'items';
            return (
              (props[resource.name][descriptor] === null && props[resource.name].error === null) ||
              props[resource.name].fetching ||
              props[resource.name].waiting
            );
          })
          .reduce((prev, next) => prev || next, false);

      componentDidUpdate(prevProps, prevState) {
        this.loaded = this.getEntitiesState(this.props);
        if (this.loaded && this.loaded !== this.getEntitiesState(prevProps)) {
          if (super.afterData instanceof Function) super.afterData();
          this.forceUpdate();
        }
      }

      render() {
        if (this.loaded) {
          return super.render();
        }
        return <LoadingComponent />;
      }
    };
