import { EVENTS as SESSION_EVENTS } from '../Sessions/actions';

export const PING_INTERVAL = 10000;
export const PONG_TIMEOUT = 5000;
export const RECOVER_TIMEOUT = 2000;
export const RECOVER_ATTEMPTS = 3;
export const PING = 'PING';
export const PONG = 'PONG';

// SOCKET_STATES
export const SOCKET_CONNECTING = 'SOCKET_CONNECTING';
export const SOCKET_OPENED = 'SOCKET_OPENED';
export const SOCKET_CLOSING = 'SOCKET_CLOSING';
export const SOCKET_CLOSED = 'SOCKET_CLOSED';

export const SOCKET_STATES = {
  0: SOCKET_CONNECTING,
  1: SOCKET_OPENED,
  2: SOCKET_CLOSING,
  3: SOCKET_CLOSED,
};

// COMMANDS
export const RESPONSE_EVENT = 'api.event.command.response';
export const MODULE_EVENTS = {
  rooms: SESSION_EVENTS,
};
