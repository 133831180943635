import { take, call, put, fork, cancel, delay } from 'redux-saga/effects';
import axios from 'axios';
import jwtDecode from 'jwt-decode';

import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  REFRESH_SUCCESS,
  REFRESH_ERROR,
  LOGOUT,
  VERIFY_TOKEN,
  VERIFY_TOKEN_ERROR,
  VERIFY_TOKEN_SUCCESS,
} from './actions';
import { AUTH_URLS } from './constants';
import { storage } from './storage';

const loginRequest = ({ ...credentials }) =>
  axios({ method: 'post', url: AUTH_URLS.LOGIN, data: credentials });
const refreshRequest = (token) =>
  axios({ method: 'post', url: AUTH_URLS.REFRESH, data: { token } });
const verifyTokenRequest = (token) =>
  axios({ method: 'post', url: AUTH_URLS.VERIFY, data: { token } });

function* refresh(oldToken) {
  try {
    const response = yield call(refreshRequest, oldToken);
    yield put({ type: REFRESH_SUCCESS, response });
    const { token } = response.data;
    storage.set('token', token);
    return { token, error: null };
  } catch (error) {
    storage.remove('token');
    yield put({ type: REFRESH_ERROR, error });
    return { token: null, error };
  }
}

function* refreshWatcher(jwt) {
  while (true) {
    const { exp } = jwtDecode(jwt);
    yield delay(exp * 1000 - new Date().getTime() - 5000);
    const { token, error } = yield call(refresh, jwt);
    if (error) break;
    else jwt = token;
  }
}

function* authorize(username, password) {
  try {
    const response = yield call(loginRequest, { username, password });
    yield put({ type: LOGIN_SUCCESS, response });
    const { token } = response.data;
    storage.set('token', token);
    yield fork(refreshWatcher, token);
  } catch (error) {
    yield put({ type: LOGIN_ERROR, error });
  }
}

export function* loginWatcher() {
  while (true) {
    const { username, password } = yield take(LOGIN);
    const task = yield fork(authorize, username, password);
    const action = yield take([LOGOUT, LOGIN_ERROR, REFRESH_ERROR]);
    storage.remove('token');
    if (action.type === LOGOUT) {
      yield cancel(task);
    }
  }
}

function* verifyToken({ token }) {
  try {
    const response = yield call(verifyTokenRequest, token);
    yield put({ type: VERIFY_TOKEN_SUCCESS, response });
    const receivedToken = response.data.token;
    storage.set('token', receivedToken);
    yield fork(refreshWatcher, token);
  } catch (error) {
    storage.remove('token');
    yield put({ type: VERIFY_TOKEN_ERROR, error });
  }
}

export function* verifyWatcher() {
  const token = yield take(VERIFY_TOKEN);
  const task = yield fork(verifyToken, token);
  const action = yield take([LOGOUT, VERIFY_TOKEN_ERROR, REFRESH_ERROR]);
  if (action.type === LOGOUT) {
    yield cancel(task);
  }
}
