import { fork, takeEvery } from 'redux-saga/effects';

import { callGenerator } from 'sagas/helpers';
import { SESSIONS_URI, SCENARIOS_URI } from 'utils/constants';
import { getSessionsActions, createSessionActions } from './actions';

function* createSession(method, uri, actions, { ...rest }) {
  const fullUri = `${uri + rest.scenarioId}/create_session/`;
  yield fork(callGenerator, method, fullUri, actions, {
    training_class_id: rest.classId,
  });
}

export function* sessionsWatcher() {
  yield takeEvery(getSessionsActions.fire, callGenerator, 'get', SESSIONS_URI, getSessionsActions);
  yield takeEvery(
    createSessionActions.fire,
    createSession,
    'post',
    SCENARIOS_URI,
    createSessionActions
  );
}
