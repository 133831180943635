import React, { Component } from 'react';

export default class LoadingComponent extends Component {
  render() {
    return (
      <div className="loader-container">
        <i className="fa fa-spinner fa-spin" />
        <div className="message-holder">Loading. . . Please wait.</div>
      </div>
    );
  }
}
