import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import withAuth from '../Account/withAuth';
import Header from '../Header';
import Classes from '../Classes';
import { getClasses } from '../Classes/actions';
import { getSessions } from '../Sessions/actions';
import { getScenarios } from '../Scenarios/actions';
import { connectToSocket, unlockQueue } from '../Socket/actions';

const mapStateToProps = (state) => ({ token: state.account.token });
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getClasses,
      getScenarios,
      getSessions,
      connectToSocket,
      unlockQueue,
    },
    dispatch
  );

@withAuth
@connect(mapStateToProps, mapDispatchToProps)
export default class Dashboard extends Component {
  static propTypes = {
    token: PropTypes.string,

    getClasses: PropTypes.func,
    getScenarios: PropTypes.func,
    getSessions: PropTypes.func,
    connectToSocket: PropTypes.func,
    unlockQueue: PropTypes.func,
  };

  componentDidMount() {
    this.props.getClasses();
    this.props.getScenarios();
    this.props.getSessions();
    this.props.connectToSocket(this.props.token);
    this.props.unlockQueue();
  }

  render() {
    return (
      <div>
        <Header />
        <Classes />
      </div>
    );
  }
}
