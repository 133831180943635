import jwtDecode, { InvalidTokenError } from 'jwt-decode';

export const getDisplayName = (WrappedComponent) =>
  WrappedComponent.displayName || WrappedComponent.name || 'Component';

export function mapArrayToObject(array, key) {
  const mapping = {};
  array.forEach((e) => {
    mapping[e[key || 'id']] = e;
  });
  return mapping;
}

function getTokenOrNull(wannabeToken) {
  try {
    jwtDecode(wannabeToken);
  } catch (e) {
    if (e instanceof InvalidTokenError) {
      return null;
    }
    throw e;
  }
  return wannabeToken;
}

export function chooseToken(urlToken, storageToken) {
  if (urlToken === null && storageToken === null) return null;
  if (urlToken === null || storageToken === null) {
    const token = urlToken || storageToken;
    return getTokenOrNull(token);
  }
  const t1 = getTokenOrNull(urlToken);
  const t2 = getTokenOrNull(storageToken);
  if (t1 === null && t2 === null) return null;
  if (t1 === null || t2 === null) {
    return t1 || t2;
  }
  const exp1 = jwtDecode(t1).exp;
  const exp2 = jwtDecode(t2).exp;
  if (exp1 > exp2) return t1;
  return t2;
}

export const uuidv4 = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (char) => {
    const r = (Math.random() * 16) | 0;
    const v = char === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
