import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'isomorphic-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';
import configureStore from 'config/store';
import App from 'modules/Entry';
import 'index.scss';

export const { store } = configureStore();

ReactDOM.render(
  <AppContainer>
    <Provider store={store}>
      <App />
    </Provider>
  </AppContainer>,
  document.getElementById('root')
);
