import { combineReducers } from 'redux';
import { accountReducer } from 'modules/Account/reducers';
import { socketReducer } from 'modules/Socket/reducers';
import { classesEntitiesReducer, classesViewReducer } from 'modules/Classes/reducers';
import { sessionsReducer } from '../modules/Sessions/reducers';
import { scenariosReducer } from '../modules/Scenarios/reducers';

const entitiesReducer = combineReducers({
  classes: classesEntitiesReducer.handler(),
  sessions: sessionsReducer.handler(),
  scenarios: scenariosReducer.handler(),
});

const viewsReducer = combineReducers({
  classes: classesViewReducer,
});

export default combineReducers({
  account: accountReducer,
  entities: entitiesReducer,
  socket: socketReducer,
  views: viewsReducer,
});
