import React from 'react';

import { CloseCircleOutlined } from '@ant-design/icons';

const ErrorHandler = ({ children, errors }) => {
  return (
    <div className="errorHandler">
      {!errors
        ? null
        : errors.map((err) => {
            return (
              <div className="errorHandler__text">
                <CloseCircleOutlined /> {err}
              </div>
            );
          })}
      {children}
    </div>
  );
};

export default ErrorHandler;
