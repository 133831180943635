import { call, put, select } from 'redux-saga/effects';
import axios from 'axios';
import { settings } from '../settings';

const apiRequest = (method, uri, token, data) =>
  axios({
    method,
    url: `${settings.DASHBOARD_API_URL}${uri}`,
    headers: {
      Authorization: `JWT ${token}`,
    },
    data,
  })
    .then((response) => ({ response }))
    .catch((error) => ({ error }));

export function* callGenerator(method, uri, actions, data) {
  const token = yield select((state) => state.account.token);
  const { response, error } = yield call(apiRequest, method, uri, token, data);
  if (response) {
    yield put({
      type: actions.success,
      payload: response.data,
    });
  } else yield put({ type: actions.fail, error });
}
