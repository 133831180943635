import { takeEvery } from 'redux-saga/effects';

import { callGenerator } from 'sagas/helpers';
import { SCENARIOS_URI } from 'utils/constants';
import { getScenariosActions } from './actions';

export function* scenariosWatcher() {
  yield takeEvery(
    getScenariosActions.fire,
    callGenerator,
    'get',
    SCENARIOS_URI,
    getScenariosActions
  );
}
