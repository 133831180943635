import Cookie from 'js-cookie';

class Storage {
  constructor() {
    this.storage = this.setStorageObject();
  }

  setStorageObject = () => {
    if (this.hasLocalStorage()) {
      return {
        get: (...args) => localStorage.getItem(...args),
        set: (...args) => localStorage.setItem(...args),
        remove: (...args) => localStorage.removeItem(...args),
      };
    }
    return {
      get: (...args) => Cookie.get(...args),
      set: (...args) => Cookie.set(...args),
      remove: (...args) => Cookie.remove(...args),
    };
  };

  hasLocalStorage = () => {
    try {
      localStorage.setItem('test', 'test');
      localStorage.removeItem('test');
      return true;
    } catch (e) {
      return false;
    }
  };

  get = (key) => this.storage.get(key);

  set = (key, value) => this.storage.set(key, value);

  remove = (key) => this.storage.remove(key);
}

export const storage = new Storage();
