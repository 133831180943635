import { uuidv4 } from 'utils/helpers';

import {
  SOCKET_CONNECT,
  UNLOCK_QUEUE,
  SOCKET_COMMAND,
  SOCKET_RECEIVE,
  SOCKET_SEND,
  createRequest,
  resolveRequest,
} from './actions';
import { MODULE_EVENTS, RESPONSE_EVENT } from './constants';
import { msgQueue } from './queue';
import SocketManager from './index';

const socketM = new SocketManager();

export const socketMiddleware = (store) => (next) => (action) => {
  if (!(action.meta === SOCKET_COMMAND)) {
    return next(action);
  }
  const res = next(action);
  switch (action.type) {
    case SOCKET_CONNECT:
      socketM.connect(action.token);
      socketM.onMessage((message) => {
        msgQueue.addMessage(message);
      });
      msgQueue.onMessage((message) => {
        store.dispatch({
          type: SOCKET_RECEIVE,
          payload: message,
          meta: SOCKET_COMMAND,
        });
      });
      break;
    case UNLOCK_QUEUE:
      msgQueue.unlock();
      break;
    case SOCKET_RECEIVE:
      const { module } = action.payload.headers;
      const { event } = action.payload.headers;
      if (event === RESPONSE_EVENT) {
        store.dispatch(resolveRequest(action.payload.headers.request, action.payload.body));
      } else if (
        Object.hasOwnProperty.call(MODULE_EVENTS, module) &&
        Object.hasOwnProperty.call(MODULE_EVENTS[module], event)
      ) {
        store.dispatch({
          type: MODULE_EVENTS[module][event],
          payload: action.payload.body,
        });
      } else {
        console.warn('Module ', module, ' or event ', event, ' not available');
      }
      break;
    case SOCKET_SEND:
      const uuid = uuidv4();
      const message = {
        headers: { ...action.payload.headers, request_id: uuid },
        body: action.payload.body,
      };
      socketM.send(JSON.stringify(message));
      store.dispatch(createRequest(uuid));
      break;
    default: {
      throw new Error('Invalid action type in socketMiddleware');
    }
  }

  return res;
};
