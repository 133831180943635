class ReduxActions {
  constructor() {
    this._actions = {};
  }

  _registerActions = (verb, resource) => {
    this._actions[`${verb.toLowerCase()}_${resource.toLowerCase()}`] = {
      fire: `${verb.toUpperCase()}_${resource.toUpperCase()}`,
      success: `${verb.toUpperCase()}_${resource.toUpperCase()}_SUCCESS`,
      fail: `${verb.toUpperCase()}_${resource.toUpperCase()}_FAIL`,
    };
  };

  getActions = (verb, resource) => {
    if (
      !Object.hasOwnProperty.call(this._actions, `${verb.toLowerCase()}_${resource.toLowerCase()}`)
    ) {
      this._registerActions(verb, resource);
    }
    return this._actions[`${verb.toLowerCase()}_${resource.toLowerCase()}`];
  };
}

export const reduxActions = new ReduxActions();
