import URLSearchParams from 'url-search-params';
import jwtDecode from 'jwt-decode';

import { chooseToken } from 'utils/helpers';
import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  REFRESH_SUCCESS,
  REFRESH_ERROR,
  LOGOUT,
  VERIFY_TOKEN_SUCCESS,
  VERIFY_TOKEN_ERROR,
} from './actions';
import { storage } from './storage';

const urlParams = new URLSearchParams(window.location.search);

const initialState = {
  isAuthenticated: false,
  token: chooseToken(urlParams.get('token'), storage.get('token')),
  error: null,
  userId: null,
  username: null,
  userType: null,
  email: null,
};

const getInfoFromToken = (token) => {
  const decodedToken = jwtDecode(token);
  return {
    userId: decodedToken.user_id,
    username: decodedToken.username,
    userType: decodedToken.user_type,
    email: decodedToken.email,
  };
};

const handlers = {
  [LOGIN_SUCCESS]: (state, action) => ({
    isAuthenticated: true,
    token: action.response.data.token,
    ...getInfoFromToken(action.response.data.token),
  }),
  [REFRESH_SUCCESS]: (state, action) => ({
    isAuthenticated: true,
    token: action.response.data.token,
    ...getInfoFromToken(action.response.data.token),
  }),
  [LOGIN_ERROR]: (state, action) => ({
    isAuthenticated: false,
    token: null,
    error: action.error,
  }),
  [REFRESH_ERROR]: (state, action) => ({
    isAuthenticated: false,
    token: null,
    error: action.error,
  }),
  [LOGOUT]: (state, action) => ({
    isAuthenticated: false,
    token: null,
    error: action.error,
  }),
  [VERIFY_TOKEN_SUCCESS]: (state, action) => ({
    isAuthenticated: true,
    token: action.response.data.token,
    ...getInfoFromToken(action.response.data.token),
  }),
  [VERIFY_TOKEN_ERROR]: (state, action) => ({
    isAuthenticated: false,
    token: null,
    error: action.error,
  }),
};

export const accountReducer = (state = initialState, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;
