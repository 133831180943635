import { reduxActions } from 'utils/reduxActions';

export const getSessionsActions = reduxActions.getActions('get', 'sessions');
export const getSessions = () => ({ type: getSessionsActions.fire });

export const createSessionActions = reduxActions.getActions('create', 'scenario');
export const createSession = (classId, scenarioId) => ({
  type: createSessionActions.fire,
  classId,
  scenarioId,
});

// Socket receive
export const ROOM_PAUSED = 'ROOM_PAUSED';
export const ROOM_RESUMED = 'ROOM_RESUMED';
export const ROOM_FINISHED = 'ROOM_FINISHED';
export const ROOM_CREATED = 'ROOM_CREATED';

export const EVENTS = {
  'room.event.paused': ROOM_PAUSED,
  'room.event.resumed': ROOM_RESUMED,
  'room.event.finished': ROOM_FINISHED,
  'dashboard.event.room_created': ROOM_CREATED,
};
