import { reduxActions } from '../../utils/reduxActions';

export const getClassesActions = reduxActions.getActions('get', 'classes');
export const getClasses = () => ({ type: getClassesActions.fire });

export const TOGGLE_CHILD_COMPONENT = 'TOGGLE_CHILD_COMPONENT';
export const toggleChildComponent = (classId, child) => ({
  type: TOGGLE_CHILD_COMPONENT,
  classId,
  child,
});
