import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { logout } from '../Account/actions';
import { ADMIN } from '../Account/constants';
import { settings } from 'settings';
import userLogo from 'img/userLogo.png';
import logoTraderion from 'img/logoTraderion.png';

const mapStateToProps = (state) => ({
  username: state.account.username,
  userType: state.account.userType,
  token: state.account.token,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ logout }, dispatch);

@connect(mapStateToProps, mapDispatchToProps)
export default class Header extends Component {
  static propTypes = {
    username: PropTypes.string,
    userType: PropTypes.string,
    token: PropTypes.string,

    logout: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      search: '',
      active: 'classes',
    };
  }

  handleChange = (e) => this.setState({ search: e.target.value });

  handleToggle = (key) => this.setState({ active: key });

  render() {
    return (
      <header>
        <div className="header-top">
          <img className="header-logo" src={logoTraderion} alt="Traderion logo" width="40px" />
          <div className="header-inner">
            <ul className="menu">
              <li onClick={() => this.handleToggle('classes')}>
                <div>
                  <span>CLASSES & SESSIONS</span>
                  {this.state.active === 'classes' ? <i className="fa fa-caret-up" /> : null}
                </div>
              </li>
              {this.props.userType.toUpperCase() === ADMIN && (
                <li>
                  <div>
                    <span
                      onClick={() => {
                        window.location = `${settings.ADMIN_FRONT_URL}?token=${this.props.token}`;
                      }}
                    >
                      ADMIN
                    </span>
                  </div>
                </li>
              )}
            </ul>
            <div className="user">
              <span>{this.props.username}</span>
              <img src={userLogo} alt="User logo" />
              <span onClick={this.props.logout}>
                {' '}
                <i className="fa fa-power-off" />{' '}
              </span>
            </div>
          </div>
        </div>
        <div className="header-bottom">
          <div className="search">
            <i className="fa fa-search" />
            <input
              value={this.state.search}
              type="search"
              placeholder="Filter by name,tags, dates, etc."
              onChange={this.handleChange}
            />
          </div>
        </div>
      </header>
    );
  }
}
