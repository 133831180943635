const settings = {};

switch (process.env.goal) {
  case 'development': {
    settings.DASHBOARD_API_URL = 'https://dashboard.dev.sim.traderion.com/api/';
    settings.DASHBOARD_SERVICE_URL = 'wss://dashboard-proxy.dev.sim.traderion.com/ws/';
    settings.TRADING_FRONT_URL = 'https://dev.sim.traderion.com/trading/';
    settings.CB_FRONT_URL = 'https://dev.sim.traderion.com/central-bank/';
    settings.ADMIN_FRONT_URL = 'https://dev.sim.traderion.com/admin/';
    break;
  }
  case 'production': {
    settings.DASHBOARD_API_URL = 'https://dashboard.sim.traderion.com/api/';
    settings.DASHBOARD_SERVICE_URL = 'wss://dashboard-proxy.sim.traderion.com/ws/';
    settings.TRADING_FRONT_URL = 'https://sim.traderion.com/trading/';
    settings.CB_FRONT_URL = 'https://sim.traderion.com/central-bank/';
    settings.ADMIN_FRONT_URL = 'https://sim.traderion.com/admin/';
    break;
  }
  default: {
    // local
    settings.DASHBOARD_API_URL = 'https://dashboard.dev.sim.traderion.com/api/';
    settings.DASHBOARD_SERVICE_URL = 'wss://dashboard-proxy.dev.sim.traderion.com/ws/';
    settings.TRADING_FRONT_URL = 'http://127.0.0.1:3001/trading/';
    settings.CB_FRONT_URL = 'http://127.0.0.1:3003/central-bank/';
    settings.ADMIN_FRONT_URL = 'http://127.0.0.1:3002/admin/';
    break;
  }
}
export { settings };
