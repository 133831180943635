import { msgQueue } from './queue';
import { UNLOCK_QUEUE, CREATE_REQUEST, RESOLVE_REQUEST, CLEAR_REQUEST } from './actions';

const initialState = {
  locked: msgQueue.isLocked(),
  requests: {},
};

const handlers = {
  [UNLOCK_QUEUE]: (state, action) => {
    msgQueue.unlock();
    return { ...state, locked: msgQueue.isLocked() };
  },
  [CREATE_REQUEST]: (state, action) => ({
    ...state,
    requests: { ...state.requests, [action.uuid]: {} },
  }),
  [RESOLVE_REQUEST]: (state, action) => ({
    ...state,
    requests: { ...state.requests, [action.uuid]: action.response },
  }),
  [CLEAR_REQUEST]: (state, action) => ({
    ...state,
    requests: (({ [action.uuid]: _, ...items }) => items)(state.requests),
  }),
};

export const socketReducer = (state = initialState, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;
