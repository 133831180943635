export const SOCKET_COMMAND = 'SOCKET_COMMAND';
export const SOCKET_RECEIVE = 'SOCKET_RECEIVE';
export const SOCKET_SEND = 'SOCKET_SEND';

export const SOCKET_CONNECT = 'SOCKET_CONNECT';
export const connectToSocket = (token) => ({
  type: SOCKET_CONNECT,
  token,
  meta: SOCKET_COMMAND,
});

export const UNLOCK_QUEUE = 'UNLOCK_QUEUE';
export const unlockQueue = () => ({ type: UNLOCK_QUEUE, meta: SOCKET_COMMAND });

export const CREATE_REQUEST = 'CREATE_REQUEST';
export const createRequest = (uuid) => ({ type: CREATE_REQUEST, uuid });

export const RESOLVE_REQUEST = 'RESOLVE_REQUEST';
export const resolveRequest = (uuid, response) => ({
  type: RESOLVE_REQUEST,
  uuid,
  response,
});

export const CLEAR_REQUEST = 'CLEAR_REQUEST';
export const clearRequest = (uuid) => ({ type: CLEAR_REQUEST, uuid });
