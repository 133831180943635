import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toggleChildComponent } from '../Classes/actions';
import { createSession } from '../Sessions/actions';
import { SESSIONS_VIEW } from '../Classes/constants';

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ createSession, toggleChildComponent }, dispatch);

const ASSET_CLASSES = ['FX', 'EQ', 'FI', 'IRD', 'BFUT', 'CM', 'CDS'];

@connect(null, mapDispatchToProps)
export default class Scenarios extends React.Component {
  static propTypes = {
    scenarios: PropTypes.object,
    classId: PropTypes.number,

    createSession: PropTypes.func,
    toggleChildComponent: PropTypes.func,
  };

  handleClick = (scenarioUUID) => {
    this.props.createSession(this.props.classId, scenarioUUID);
    this.props.toggleChildComponent(this.props.classId, SESSIONS_VIEW);
  };

  scenariosToDOM = () =>
    Object.values(this.props.scenarios.items).map((scenario) => (
      <tr key={scenario.id}>
        <td>{scenario.name}</td>
        <td>{scenario.difficulty_display.toUpperCase()}</td>
        <td>{scenario.volatility_display.toUpperCase()}</td>
        <td>
          <button
            className="btn-blue-big"
            type="button"
            onClick={() => this.handleClick(scenario.uuid)}
          >
            <i className="fa fa-play-circle" />
            <span>START</span>
          </button>
        </td>
      </tr>
    ));

  render() {
    const scenarios = this.scenariosToDOM();
    return (
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <td>SCENARIO NAME</td>
              <td>DIFFICULTY</td>
              <td>VOLATILITY</td>
              <td>ACTION AREA</td>
            </tr>
          </thead>
          <tbody>{scenarios}</tbody>
        </table>
      </div>
    );
  }
}
