import { ApiCallReducers } from 'utils/ApiCallReducers';
import { mapArrayToObject } from 'utils/helpers';
import { createSessionActions, ROOM_CREATED } from '../Sessions/actions';
import { getClassesActions, TOGGLE_CHILD_COMPONENT } from './actions';
import { SESSIONS_VIEW } from './constants';

export const classesEntitiesReducer = new ApiCallReducers(getClassesActions, {
  successCallback: mapArrayToObject,
});

const addSessionIfNotInList = (sessions, newSession) =>
  sessions.find((e) => e === newSession.uuid) ? [...sessions] : [...sessions, newSession.uuid];

classesEntitiesReducer.addCustomActions({
  [createSessionActions.success]: (state, action) => ({
    ...state,
    items: {
      ...state.items,
      [action.payload.training_class]: {
        ...state.items[action.payload.training_class],
        training_sessions: addSessionIfNotInList(
          state.items[action.payload.training_class].training_sessions,
          action.payload
        ),
      },
    },
  }),
  [ROOM_CREATED]: (state, action) => ({
    ...state,
    items: {
      ...state.items,
      [action.payload.training_class]: {
        ...state.items[action.payload.training_class],
        training_sessions: addSessionIfNotInList(
          state.items[action.payload.training_class].training_sessions,
          action.payload
        ),
      },
    },
  }),
});

const handlers = {
  [getClassesActions.success]: (state, action) =>
    Object.keys(mapArrayToObject(action.payload)).reduce(
      (acc, id) => ({
        ...acc,
        [id]: { child: SESSIONS_VIEW },
      }),
      {}
    ),
  [TOGGLE_CHILD_COMPONENT]: (state, action) => ({
    ...state,
    [action.classId]: {
      ...state[action.classId],
      child: action.child,
    },
  }),
};

export const classesViewReducer = (state = {}, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;
