import { all } from 'redux-saga/effects';

import { loginWatcher, verifyWatcher } from 'modules/Account/sagas';
import { scenariosWatcher } from '../modules/Scenarios/sagas';
import { classesWatcher } from '../modules/Classes/sagas';
import { sessionsWatcher } from '../modules/Sessions/sagas';

export default function* rootSaga() {
  yield all([
    loginWatcher(),
    verifyWatcher(),
    classesWatcher(),
    scenariosWatcher(),
    sessionsWatcher(),
  ]);
}
