import { ApiCallReducers } from 'utils/ApiCallReducers';
import { mapArrayToObject } from 'utils/helpers';
import {
  createSessionActions,
  getSessionsActions,
  ROOM_FINISHED,
  ROOM_RESUMED,
  ROOM_PAUSED,
  ROOM_CREATED,
} from './actions';

export const sessionsReducer = new ApiCallReducers(getSessionsActions, {
  successCallback: (data) => mapArrayToObject(data, 'uuid'),
});

sessionsReducer.addCustomActions({
  [createSessionActions.success]: (state, action) => ({
    ...state,
    items: {
      ...state.items,
      [action.payload.uuid]: action.payload,
    },
  }),
  [ROOM_CREATED]: (state, action) => ({
    ...state,
    items: {
      ...state.items,
      [action.payload.uuid]: action.payload,
    },
  }),
  [ROOM_FINISHED]: (state, action) => ({
    ...state,
    items: {
      ...state.items,
      [action.payload.room_id]: {
        ...state.items[action.payload.room_id],
        ...action.payload,
      },
    },
  }),
  [ROOM_RESUMED]: (state, action) => ({
    ...state,
    items: {
      ...state.items,
      [action.payload.room_id]: {
        ...state.items[action.payload.room_id],
        ...action.payload,
      },
    },
  }),
  [ROOM_PAUSED]: (state, action) => ({
    ...state,
    items: {
      ...state.items,
      [action.payload.room_id]: {
        ...state.items[action.payload.room_id],
        ...action.payload,
      },
    },
  }),
});
