export const PENDING = 'ACTIVE';
export const PLAYING = 'ACTIVE';
export const PAUSED = 'ACTIVE';
export const FINISHED = 'COMPLETED';

export const SESSION_STATUSES = {
  0: PENDING,
  1: PLAYING,
  2: PAUSED,
  3: FINISHED,
};
